.btn-today-link {
  @extend .btn-link;

  color: #212529;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
