.energy-handling-chart-bar {
  display: flex;
  flex-direction: row;

  .energy-handling-item-container {
    .energy-handling-item-block {
      border: 1px solid white;
      height: 20px;
      border-radius: 0.2rem;
    }
    .energy-handling-item-text {
      @extend .text-muted;
      width: 100%;
      font-size: 10px;
      text-align: center;

      &.rotate {
        transform: rotate(-60deg) translate(-10px, 0%);
        white-space: nowrap;
        text-align: center;
      }
    }

    &:has(.energy-handling-item-text.rotate) {
      height: 50px;
    }
  }
}
