.energy-flow-card {
  .energy-flow-icon-text {
    @extend .text-center-nowrap;
    width: 42px;

    &.second {
      @extend .text-muted;
      font-size: 0.9rem;
      margin-top: -5px;
    }
  }

  .icon-container {
    font-size: 2.5rem;
  }
  @include media-breakpoint-down(lg) {
    .icon-container {
      font-size: 2rem;
    }
    .energy-flow-icon-text {
      width: 34px;
    }
  }

  .side-column-spacer {
    min-height: 125px;
  }
  @include media-breakpoint-down(lg) {
    .side-column-spacer {
      min-height: 112px;
    }
  }

  .icon-container-center {
    @extend .icon-container;
    padding: 1rem;
  }
}

.text-center-nowrap {
  text-align: center;
  white-space: nowrap;
  &:before {
    content: "";
    margin-left: -100%;
  }
  &:after {
    content: "";
    margin-right: -100%;
  }
}
