.bank-card {
  width: 300px;
  max-width: 100%;
  aspect-ratio: 5 / 3;
  color: #fff;
  perspective: 1000px;

  .card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
  }

  .front {
    width: 100%;
    height: 100%;
    background-image: url("../img/bank-card/map.png"), linear-gradient(45deg, $gradient-color1, $gradient-color2);
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 30px;
    border-radius: 12px;
    overflow: hidden;
    z-index: 1;
    backface-visibility: hidden;
  }

  .chip-container, .bank-container {
    width: 15%;
    img {
      width: 100%;
    }
  }

  .card-text {
    margin-top: 30px;
    font-size: 14px;
  }

  .card-holder {
    opacity: 0.7;
    text-transform: uppercase;
    letter-spacing: 0.08rem;
    margin-bottom: -3px;
  }
}


