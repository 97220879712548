$sidebar-size: 330px;

.installation-detail-overview-container {
  display: flex;
  flex-direction: row;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  .col-main {
    flex-grow: 1;
    width: calc(100% - $sidebar-size);
    @include media-breakpoint-up(lg) {
      margin-right: 15px;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .col-sidebar {
    flex-grow: 1;
    @include media-breakpoint-up(lg) {
      width: $sidebar-size;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}
