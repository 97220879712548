.bottom-tabbar {
  border-top: $border-color 1px solid;
  box-shadow: none;
  background-color: white !important;
  padding-bottom: calc(0.5rem + env(safe-area-inset-bottom));
}

.bottom-tabbar-link {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9rem;
  }
  .bottom-tabbar-icon {
    @include media-breakpoint-up(sm) {
      font-size: 1.3rem;
      margin-right: 8px;
    }
  }

  @include media-breakpoint-down(xs) {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 0.8rem;
  }
  .bottom-tabbar-icon {
    @include media-breakpoint-down(xs) {
      font-size: 1.2rem;
    }
  }
}
