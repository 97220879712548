.livenow {
  width: 22px;
  height: 22px;
  div {
    vertical-align: middle;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    position: absolute;
    margin: 0 auto;
    border: 3px solid rgba(255, 255, 255, 1);

    &:nth-child(1) {
      margin: 2px;
      width: 18px;
      height: 18px;
      background-color: $primary;
    }

    &:nth-child(2) {
      background-color: $primary;
      border: 3px solid $primary;
      opacity: 0.25;
    }

    &:nth-child(3) {
      border: 3px solid $primary;
      opacity: 0;
    }
  }
  &.animate {
    div:nth-child(3) {
      opacity: 1;
      -webkit-animation: live 3s normal forwards ease-in-out;
      animation: live 3s normal forwards ease-in-out;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }
}

@-webkit-keyframes live {
  0%, 80%, 100% {
    -webkit-transform: scale(0.5)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes live {
  0%, 80%, 100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
  }
  40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
