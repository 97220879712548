.th-sticky-1,
.table .thead-light tr.tr-sticky-1 th,
tr.tr-sticky-1 th {
  position: sticky;
  top: 56px;
  background-color: #F9FBFD;
  z-index: 5;
  border-bottom: 1px solid #E3EBF6;
}

.th-sticky-2,
.table .thead-light tr.tr-sticky-2 th,
tr.tr-sticky-2 th {
  position: sticky;
  top: 56px + 58px;
  background-color: #F9FBFD;
  z-index: 5;
  box-shadow: 0 1px #E3EBF6;
}

.tr-vertical-border {
  th {
    border-left: 1px solid #E3EBF6;
    border-right: none;
    &:first-child {
      border-left: 0;
    }
  }
}
